<template>
  <v-container class="tabBackground">
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="fields"
          item-key="key"
          hide-default-footer
          class="elevation-1"
        >
          <!-- Key Column -->
          <template #[`item.key`]="{ item }">
            <td>{{ item.key }}</td>
          </template>

          <!-- Description Column -->
          <template #[`item.description`]="{ item }">
            <td class="small-text">
              {{ item.description }}
            </td>
          </template>

          <template #[`item.active`]="{ item }">
            <td class="text-center">
              <v-switch
                v-model="item.active"
                color="secondary"
                @click.stop="toggle2FA(item)"
              />
            </td>
          </template>

          <!-- Actions Column -->
          <template #[`item.actions`]="{ item }">
            <td class="text-center">
              <v-icon
                class="mx-2 hover-actions"

                @click="showAddDialog(item)"
              >
                more_vert
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OrganizationSettings',
  data() {
    return {
      twoFactorAuthEnabled: false,
      headers:              [
        {
          text:     this.$t('organizationSettingsTab.fields.key'),
          value:    'key',
          align:    'start',
          sortable: false,
        },
        {
          text:     this.$t('organizationSettingsTab.fields.description'),
          value:    'description',
          align:    'start',
          sortable: false,
        },
        {
          text:     this.$t('organizationSettingsTab.fields.active'),
          value:    'active',
          align:    'start',
          sortable: false,
        },
        {
          text:     this.$t('organizationSettingsTab.fields.actions'),
          value:    'actions',
          align:    'center',
          sortable: false,
        },
      ],
      fields: [
        {
          key:         this.$t('organizationSettingsTab.fields.name'),
          description: this.$t('organizationSettingsTab.fields.details'),
          active:      true,
        },
      ],
    };
  },
  methods: {
    toggle2FA(item) {
      // eslint-disable-next-line no-console
      console.log('2FA toggled for:', item.key, item.active);
    },
    showAddDialog() {
      // eslint-disable-next-line no-console
      console.log('Add button clicked');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }

  .small-text {
    color: #9e9e9e;
    font-size: 0.875rem;
  }
</style>
